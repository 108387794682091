import React from "react";

type AffixContainerProps = {
  isPrefix: boolean;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  affix?: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

export const AffixContainer = React.memo(
  ({ isPrefix, prefix, suffix, affix, children }: AffixContainerProps) => (
    <>
      {isPrefix ? prefix ?? affix : <></>}
      {children}
      {!isPrefix ? suffix ?? affix : <></>}
    </>
  )
);
