import Konva from "konva";
import React, { useEffect, useRef } from "react";
import { Group } from "react-konva";
import { useSelector } from "react-redux";
import { numberGuard } from "src/helper";
import { useDrag, useLayer, useScheme, useTransform } from "src/hooks";
import { RootState } from "src/redux";
import {
  GroupObjLayerData,
  LogoObjLayerData,
  MovableObjLayerData,
  OverlayObjLayerData,
  PartialAllLayerData,
  ShapeBaseObjLayerData,
  TextObjLayerData,
} from "src/types/common";
import { LayerTypes, MouseModes } from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";

import { LogoLayer } from "./LogoLayer";
import { OverlayLayer } from "./OverlayLayer";
import { ShapeLayer } from "./ShapeLayer";
import { TextLayer } from "./TextLayer";
import { MovableLayerProps } from "./types";

export const GroupLayer = React.memo(
  ({
    stageRef,
    editable,
    virtual,
    specMode,
    layer,
    onSetTransformingLayer,
    onHover,
    onLayerDragStart,
    onLayerDragEnd,
  }: MovableLayerProps<GroupObjLayerData>) => {
    const {
      layerList,
      cloningLayers,
      onLoadLayer,
      onLayerSelect: onSelect,
      onLayerDataChange: onChange,
      onCloneMoveLayer: onCloneMove,
      onDblClickLayer: onDblClick,
    } = useLayer();
    const shapeRef = useRef<Konva.Group>(null);

    const handleChange = (
      values: PartialAllLayerData,
      pushingToHistory?: boolean
    ) => onChange(layer, values, pushingToHistory);
    const handleSelect = () => onSelect(layer);
    const handleHover = (flag: boolean) => onHover?.(layer, flag);

    const { guideData } = useScheme();
    const id = `${virtual ? "virtual-" : ""}${layer.id?.toString() ?? ""}`;

    const frameSize = useSelector(
      (state: RootState) => state.boardReducer.frameSize
    );
    const mouseMode = useSelector(
      (state: RootState) => state.boardReducer.mouseMode
    );
    const boardRotate = useSelector(
      (state: RootState) => state.boardReducer.boardRotate
    );
    const paintingGuides = useSelector(
      (state: RootState) => state.boardReducer.paintingGuides
    );

    const layerData = layer.layer_data as GroupObjLayerData;
    const childLayers: BuilderLayerJSON[] =
      layerData.layers
        ?.map(
          (layerId) =>
            layerList.find(
              (layer) => layer.id.toString() === layerId.toString()
            ) as BuilderLayerJSON
        )
        .filter((item) => !!item) ?? [];
    const listening =
      !layer.layer_locked &&
      mouseMode === MouseModes.DEFAULT &&
      !layer.layer_data.editLock;

    const {
      dragEnabled,
      handleDragStart,
      handleDragMove,
      handleDragEnd,
      handleTouchMove,
      handleTouchEnd,
    } = useDrag({
      stageRef,
      shapeRef,
      paintingGuides,
      guideData,
      frameSize,
      layer,
      cloningLayers: cloningLayers as BuilderLayerJSON<MovableObjLayerData>[],
      onSelect: handleSelect,
      onChange: handleChange,
      onDragStart: onLayerDragStart,
      onDragEnd: onLayerDragEnd,
      onCloneMove,
      onSetTransformingLayer,
    });
    const {
      handleTransformStart,
      handleTransformEnd,
      handleTransform,
    } = useTransform({
      shapeRef,
      layer,
      onChange: handleChange,
      onDragStart: onLayerDragStart,
      onDragEnd: onLayerDragEnd,
      onSetTransformingLayer,
    });

    useEffect(() => {
      if (id) onLoadLayer?.(id, true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Group
        id={id?.toString()}
        key={id}
        name={id}
        ref={shapeRef}
        x={numberGuard(layerData.left)}
        y={numberGuard(layerData.top)}
        rotation={numberGuard(layerData.rotation)}
        boardRotate={numberGuard(boardRotate)}
        scaleX={(layerData.scaleX || 1) * (layerData.flop === 1 ? -1 : 1)}
        scaleY={(layerData.scaleY || 1) * (layerData.flip === 1 ? -1 : 1)}
        visible={layer.layer_visible ? true : false}
        opacity={layerData.opacity}
        listening={listening}
        skewX={
          Math.abs(layerData.skewX) >= 1
            ? layerData.skewX / 10
            : layerData.skewX
        }
        skewY={
          Math.abs(layerData.skewY) >= 1
            ? layerData.skewY / 10
            : layerData.skewY
        }
        onClick={handleSelect}
        onDblClick={onDblClick}
        onTap={handleSelect}
        onContextMenu={handleSelect}
        draggable={!!onChange && editable && dragEnabled}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTransformStart={handleTransformStart}
        onTransformEnd={handleTransformEnd}
        onTransform={handleTransform}
        onMouseOver={() => listening && handleHover?.(true)}
        onMouseOut={() => listening && handleHover?.(false)}
      >
        {childLayers.map((childLayer) => {
          switch (childLayer.layer_type) {
            case LayerTypes.SHAPE:
              return (
                <ShapeLayer
                  key={childLayer.id}
                  layer={childLayer as BuilderLayerJSON<ShapeBaseObjLayerData>}
                  stageRef={stageRef}
                  editable={editable}
                  virtual={virtual}
                  specMode={specMode}
                  insideGroup
                />
              );
            case LayerTypes.LOGO:
            case LayerTypes.UPLOAD:
              return (
                <LogoLayer
                  key={childLayer.id}
                  layer={childLayer as BuilderLayerJSON<LogoObjLayerData>}
                  stageRef={stageRef}
                  editable={editable}
                  virtual={virtual}
                  specMode={specMode}
                  insideGroup
                />
              );
            case LayerTypes.OVERLAY:
              return (
                <OverlayLayer
                  key={childLayer.id}
                  layer={childLayer as BuilderLayerJSON<OverlayObjLayerData>}
                  stageRef={stageRef}
                  editable={editable}
                  virtual={virtual}
                  specMode={specMode}
                  insideGroup
                />
              );
            case LayerTypes.TEXT:
              return (
                <TextLayer
                  key={childLayer.id}
                  layer={childLayer as BuilderLayerJSON<TextObjLayerData>}
                  stageRef={stageRef}
                  editable={editable}
                  virtual={virtual}
                  specMode={specMode}
                  insideGroup
                />
              );
            default:
              return <></>;
          }
        })}
      </Group>
    );
  }
);
