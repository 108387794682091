import { LayerTypes, MouseModes } from "src/types/enum";

export const FinishOptions = [
  {
    label: "Normal",
    value: "#000000",
    base: "normal",
  },
  {
    label: "Glossy",
    value: "#0F0F00",
    base: "glossy",
  },
  {
    label: "Flat",
    value: "#00FF00",
    base: "flat",
  },
  {
    label: "Matte",
    value: "#1C8300",
    base: "matte",
  },
  {
    label: "Semi-Metallic",
    value: "#BB2900",
    base: "semi-metallic",
  },
  {
    label: "Metallic",
    value: "#FF5500",
    base: "metallic",
  },
  {
    label: "Chrome",
    value: "#FF0000",
    base: "chrome",
  },
];

export const DefaultLayer = {
  upload_id: 0,
  layer_visible: 1,
  layer_locked: 0,
  layer_order: 1,
  time_modified: 0,
  confirm: "",
  layer_data: {
    name: "",
    text: "",
    width: 0,
    height: 0,
    radius: 0,
    innerRadius: 0,
    outerRadius: 0,
    left: 0,
    top: 0,
    rotation: 0,
    flop: 0,
    flip: 0,
    scaleX: 1,
    scaleY: 1,
    skewX: 0,
    skewY: 0,
    stroke: 0,
    paddingX: 0,
    paddingY: 0,
    strokeType: "middle",
    font: 0,
    size: 0,
    sizeLocked: true,
    scolor: undefined,
    color: undefined,
    bgColor: undefined,
    blendType: "normal",
    opacity: 1,
    shadowColor: undefined,
    shadowBlur: 0,
    shadowOpacity: 1,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    cornerTopLeft: 0,
    cornerTopRight: 0,
    cornerBottomLeft: 0,
    cornerBottomRight: 0,
    numPoints: 5,
    angle: 60,
    points: [],
    lineCap: "round",
    lineJoin: "round",
    pointerLength: 20,
    pointerWidth: 20,
    finish: FinishOptions[0].value,
    finishBase: FinishOptions[0].base,
    type: "",
    editLock: undefined,
    showOnTop: undefined,
    ownerForGallery: undefined,
  },
};

export const funWords = [
  "Amazing",
  "Astounding",
  "Awesome",
  "Beautiful",
  "Breathtaking",
  "Brilliant",
  "Clean",
  "Cool",
  "Custom",
  "Dandy",
  "Divine",
  "Electric",
  "Elegant",
  "Excellent",
  "Fancy",
  "Fantastic",
  "Fast",
  "Fine",
  "Glorious",
  "Gorgeous",
  "Grand",
  "Hot",
  "Lovely",
  "Magnificent",
  "Marvelous",
  "Neat",
  "New",
  "Nifty",
  "Pleasant",
  "Quick",
  "Radiant",
  "Remarkable",
  "Sensational",
  "Shiny",
  "Sleek",
  "Snappy",
  "Speedy",
  "Spiffy",
  "Splendid",
  "Sporty",
  "Striking",
  "Stunning",
  "Stylish",
  "Super",
  "Superior",
  "Wonderful",
];

export const AllowedLayerProps = {
  [LayerTypes.TEXT]: [
    "layer_visible",
    "layer_locked",
    "clone",
    "delete",
    "layer_data",
    "layer_data.name",
    "layer_data.text",
    "layer_data.left",
    "layer_data.top",
    "layer_data.scaleX",
    "layer_data.scaleY",
    "layer_data.skewX",
    "layer_data.skewY",
    "layer_data.rotation",
    "layer_data.flop",
    "layer_data.flip",
    "layer_data.font",
    "layer_data.color",
    "layer_data.size",
    "layer_data.sizeLocked",
    "layer_data.stroke",
    "layer_data.scolor",
    "layer_data.opacity",
    "layer_data.shadowColor",
    "layer_data.shadowBlur",
    "layer_data.shadowOpacity",
    "layer_data.shadowOffsetX",
    "layer_data.shadowOffsetY",
    "layer_data.finish",
    "layer_data.finishBase",
    "layer_data.legacy",
    "layer_data.editLock",
    "layer_data.showOnTop",
    "layer_data.ownerForGallery",
  ],
  [LayerTypes.LOGO]: [
    "layer_visible",
    "layer_locked",
    "clone",
    "delete",
    "layer_data",
    "layer_data.name",
    "layer_data.width",
    "layer_data.height",
    "layer_data.left",
    "layer_data.sizeLocked",
    "layer_data.skewX",
    "layer_data.skewY",
    "layer_data.source_file",
    "layer_data.preview_file",
    "layer_data.top",
    "layer_data.rotation",
    "layer_data.flop",
    "layer_data.flip",
    "layer_data.color",
    "layer_data.opacity",
    "layer_data.shadowColor",
    "layer_data.shadowBlur",
    "layer_data.shadowOpacity",
    "layer_data.shadowOffsetX",
    "layer_data.shadowOffsetY",
    "layer_data.paddingX",
    "layer_data.paddingY",
    "layer_data.bgColor",
    "layer_data.bgCornerTopLeft",
    "layer_data.bgCornerTopRight",
    "layer_data.bgCornerBottomLeft",
    "layer_data.bgCornerBottomRight",
    "layer_data.finish",
    "layer_data.finishBase",
    "layer_data.legacy",
    "layer_data.editLock",
    "layer_data.showOnTop",
    "layer_data.ownerForGallery",
  ],
  [LayerTypes.OVERLAY]: [
    "layer_visible",
    "layer_locked",
    "clone",
    "delete",
    "layer_data",
    "layer_data.name",
    "layer_data.width",
    "layer_data.height",
    "layer_data.sizeLocked",
    "layer_data.skewX",
    "layer_data.skewY",
    "layer_data.left",
    "layer_data.top",
    "layer_data.rotation",
    "layer_data.flop",
    "layer_data.flip",
    "layer_data.opacity",
    "layer_data.color",
    "layer_data.shadowColor",
    "layer_data.shadowBlur",
    "layer_data.shadowOpacity",
    "layer_data.shadowOffsetX",
    "layer_data.shadowOffsetY",
    "layer_data.stroke",
    "layer_data.stroke_scale",
    "layer_data.scolor",
    "layer_data.finish",
    "layer_data.finishBase",
    "layer_data.legacy",
    "layer_data.editLock",
    "layer_data.showOnTop",
    "layer_data.ownerForGallery",
  ],
  [LayerTypes.SHAPE]: {
    [MouseModes.RECT]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.width",
      "layer_data.height",
      "layer_data.sizeLocked",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.left",
      "layer_data.top",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.cornerTopLeft",
      "layer_data.cornerTopRight",
      "layer_data.cornerBottomLeft",
      "layer_data.cornerBottomRight",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.CIRCLE]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.radius",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.sizeLocked",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.ELLIPSE]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.width",
      "layer_data.height",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.sizeLocked",
      "layer_data.left",
      "layer_data.top",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.STAR]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.innerRadius",
      "layer_data.outerRadius",
      "layer_data.numPoints",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.sizeLocked",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.RING]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.innerRadius",
      "layer_data.outerRadius",
      "layer_data.sizeLocked",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.REGULARPOLYGON]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.radius",
      "layer_data.numPoints",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.sizeLocked",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.WEDGE]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.radius",
      "layer_data.angle",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.sizeLocked",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.ARC]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.sizeLocked",
      "layer_data.innerRadius",
      "layer_data.outerRadius",
      "layer_data.angle",
      "layer_data.left",
      "layer_data.top",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.LINE]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.points",
      "layer_data.lineCap",
      "layer_data.lineJoin",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.blendType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.POLYGON]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.points",
      "layer_data.lineCap",
      "layer_data.lineJoin",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.opacity",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.ARROW]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.points",
      "layer_data.lineCap",
      "layer_data.lineJoin",
      "layer_data.pointerLength",
      "layer_data.pointerWidth",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.color",
      "layer_data.blendType",
      "layer_data.opacity",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.strokeType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
    [MouseModes.PEN]: [
      "layer_visible",
      "layer_locked",
      "clone",
      "delete",
      "layer_data",
      "layer_data.name",
      "layer_data.type",
      "layer_data.left",
      "layer_data.top",
      "layer_data.scaleX",
      "layer_data.scaleY",
      "layer_data.skewX",
      "layer_data.skewY",
      "layer_data.points",
      "layer_data.lineCap",
      "layer_data.lineJoin",
      "layer_data.rotation",
      "layer_data.flop",
      "layer_data.flip",
      "layer_data.opacity",
      "layer_data.stroke",
      "layer_data.scolor",
      "layer_data.blendType",
      "layer_data.shadowColor",
      "layer_data.shadowBlur",
      "layer_data.shadowOpacity",
      "layer_data.shadowOffsetX",
      "layer_data.shadowOffsetY",
      "layer_data.finish",
      "layer_data.finishBase",
      "layer_data.editLock",
      "layer_data.showOnTop",
      "layer_data.ownerForGallery",
    ],
  },
  [LayerTypes.UPLOAD]: [
    "layer_visible",
    "layer_locked",
    "clone",
    "delete",
    "layer_data",
    "layer_data.id",
    "layer_data.name",
    "layer_data.width",
    "layer_data.height",
    "layer_data.left",
    "layer_data.top",
    "layer_data.skewX",
    "layer_data.skewY",
    "layer_data.sizeLocked",
    "layer_data.rotation",
    "layer_data.flop",
    "layer_data.flip",
    "layer_data.opacity",
    "layer_data.color",
    "layer_data.shadowColor",
    "layer_data.shadowBlur",
    "layer_data.shadowOpacity",
    "layer_data.shadowOffsetX",
    "layer_data.shadowOffsetY",
    "layer_data.paddingX",
    "layer_data.paddingY",
    "layer_data.bgColor",
    "layer_data.bgCornerTopLeft",
    "layer_data.bgCornerTopRight",
    "layer_data.bgCornerBottomLeft",
    "layer_data.bgCornerBottomRight",
    "layer_data.finish",
    "layer_data.finishBase",
    "layer_data.legacy",
    "layer_data.fromOldSource",
    "layer_data.editLock",
    "layer_data.showOnTop",
    "layer_data.ownerForGallery",
  ],
  [LayerTypes.BASE]: [
    "layer_visible",
    "delete",
    "layer_data",
    "layer_data.name",
    "layer_data.opacity",
    "layer_data.color",
    "layer_data.finish",
    "layer_data.finishBase",
    "layer_data.editLock",
    "layer_data.ownerForGallery",
  ],
  [LayerTypes.CAR]: [
    "layer_visible",
    "layer_data",
    "layer_data.name",
    "layer_data.color",
    "layer_data.finish",
    "layer_data.finishBase",
  ],
  [LayerTypes.GROUP]: [
    "layer_visible",
    "layer_locked",
    "ungroup",
    "clone",
    "delete",
    "layer_data",
    "layer_data.name",
    "layer_data.left",
    "layer_data.top",
    "layer_data.scaleX",
    "layer_data.scaleY",
    "layer_data.skewX",
    "layer_data.skewY",
    "layer_data.opacity",
    "layer_data.rotation",
    "layer_data.flop",
    "layer_data.flip",
    "layer_data.finish",
    "layer_data.finishBase",
    "layer_data.editLock",
    "layer_data.showOnTop",
    "layer_data.ownerForGallery",
  ],
};

export const TemplateVariables = {
  PROFILE_NAME: "%NAME%",
  PROFILE_AVATAR: "%AVATAR%",
  TWITTER_NAME: "%TWITTER_NAME%",
  FACEBOOK_NAME: "%FACEBOOK_NAME%",
  INSTAGRAM_NAME: "%INSTAGRAM_NAME%",
  TWITCH_NAME: "%TWITCH_NAME%",
  YOUTUBE_NAME: "%YOUTUBE_NAME%",
  WEBSITE_URL: "%WEBSITE_URL%",
};

export const PressedKey = {
  Meta: "meta",
  Shift: "shift",
  Alt: "alt",
  Ctrl: "ctrl",
};

export const MovableLayerTypes = [
  LayerTypes.SHAPE,
  LayerTypes.OVERLAY,
  LayerTypes.LOGO,
  LayerTypes.TEXT,
  LayerTypes.UPLOAD,
  LayerTypes.GROUP,
];

export const DeletableLayerTypes = [...MovableLayerTypes, LayerTypes.BASE];
