import { useFeatureFlag } from "configcat-react";
import Konva from "konva";
import { Group } from "konva/lib/Group";
import { Stage } from "konva/lib/Stage";
import _ from "lodash";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { usePageVisibility } from "react-page-visibility";
import { useDispatch, useSelector } from "react-redux";
import LayerDeleteDialog from "src/components/dialogs/LayerDeleteDialog";
import {
  decodeHtml,
  detectBrowser,
  focusBoard,
  getZoomedCenterPosition,
  isInSameSideBar,
  isWindows,
} from "src/helper";
import { useLayer, useZoom } from "src/hooks";
import { RootState } from "src/redux";
import {
  historyActionBack,
  historyActionUp,
  setBoardRotate,
  setMouseMode,
  setPaintingGuides,
  setPressedEventKey,
  setPressedKey,
  setZoom,
} from "src/redux/reducers/boardReducer";
import { setAskingSimPreviewByLatest } from "src/redux/reducers/downloaderReducer";
import {
  bulkUpdateLayer,
  cloneLayer,
  CloneLayerListOptions,
  CloneLayerOptions,
  cloneLayers,
  deleteLayer,
  deleteLayerList,
  groupLayers,
  mergeListItems as updateLayerListOnly,
  setClipboardLayers,
  setDrawingStatus,
  setSelectedLayerIds,
  updateLayer,
} from "src/redux/reducers/layerReducer";
import { deleteUpload } from "src/redux/reducers/uploadReducer";
import SchemeService from "src/services/schemeService";
import {
  CSSStyleDeclarationWithMozTransform,
  GroupObjLayerData,
  MovableObjLayerData,
  TextObjLayerData,
  UploadObjLayerData,
} from "src/types/common";
import {
  Browser,
  ConfigCatFlags,
  DialogTypes,
  DrawingStatus,
  LayerTypes,
  MouseModes,
  PaintingGuides,
  PaintingGuideStatus,
} from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";

export const ArrowKeys = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];
const BracketKeys = ["[", "]"];

export interface WithKeyEventProps {
  editable: boolean;
  stageRef: RefObject<Stage>;
  baseLayerRef: RefObject<Group>;
  mainLayerRef: RefObject<Group>;
  carMakeLayerRef: RefObject<Group>;
  carMaskLayerRef: RefObject<Group>;
  activeTransformerRef: RefObject<Konva.Transformer>;
  virtualStageRef: RefObject<Stage>;
  virtualBaseLayerRef: RefObject<Group>;
  virtualMainLayerRef: RefObject<Group>;
  virtualCarMakeLayerRef: RefObject<Group>;
  virtualCarMaskLayerRef: RefObject<Group>;
}

export interface ComponentWithKeyEventProps extends WithKeyEventProps {
  dialog?: DialogTypes | null;
  setDialog: (dialog?: DialogTypes | null) => void;
  unsetDeleteLayerState: () => void;
  onKeyEvent: (key: string, event: KeyboardEvent) => void;
  onDeleteLayers: (layers: BuilderLayerJSON[]) => void;
  onCloneLayer: (
    layerToClone: BuilderLayerJSON<MovableObjLayerData>,
    options?: CloneLayerOptions
  ) => void;
  onCloneLayerList: (
    layerToClone: BuilderLayerJSON<MovableObjLayerData>[],
    options?: CloneLayerListOptions
  ) => void;
  onGroupLayers: (layers: BuilderLayerJSON[]) => void;
  onTogglePaintingGuides: (guide: PaintingGuides) => void;
  onUngroupLayer: (layer: BuilderLayerJSON<GroupObjLayerData>) => void;
}

export const withKeyEvent = (Component: React.FC<ComponentWithKeyEventProps>) =>
  React.memo((props: WithKeyEventProps) => {
    const dispatch = useDispatch();
    const isVisible = usePageVisibility();
    const { editable, stageRef } = props;
    const { onZoomIn, onZoomOut, onZoomFit } = useZoom(stageRef);
    const { value: enableSimPreview } = useFeatureFlag(
      ConfigCatFlags.SIM_PREVIEW,
      true
    );
    const [deleteLayerState, setDeleteLayerState] = useState<{
      show?: boolean;
      deleteUpload?: boolean;
      message?: string;
    }>({});
    const [dialog, setDialog] = useState<DialogTypes | null | undefined>(null);
    const [browserZoom, setBrowserZoom] = useState(1);

    const tick = useRef(0);
    const prevTick = useRef(0);

    const { currentLayer, selectedLayers, selectedLayerIds } = useLayer();
    const clipboardLayers = useSelector(
      (state: RootState) => state.layerReducer.clipboardLayers
    );
    const layerList = useSelector(
      (state: RootState) => state.layerReducer.list
    );
    const uploadList = useSelector(
      (state: RootState) => state.uploadReducer.list
    );

    const pressedKey = useSelector(
      (state: RootState) => state.boardReducer.pressedKey
    );
    const pressedEventKey = useSelector(
      (state: RootState) => state.boardReducer.pressedEventKey
    );
    const boardRotate = useSelector(
      (state: RootState) => state.boardReducer.boardRotate
    );
    const mouseMode = useSelector(
      (state: RootState) => state.boardReducer.mouseMode
    );
    const zoom = useSelector((state: RootState) => state.boardReducer.zoom);
    const frameSize = useSelector(
      (state: RootState) => state.boardReducer.frameSize
    );
    const paintingGuides = useSelector(
      (state: RootState) => state.boardReducer.paintingGuides
    );
    const currentScheme = useSelector(
      (state: RootState) => state.schemeReducer.current
    );

    const unsetDeleteLayerState = useCallback(() => {
      dispatch(setPressedKey(null));
      dispatch(setPressedEventKey(null));
      setDeleteLayerState({});
    }, [dispatch]);

    const togglePaintingGuides = useCallback(
      (guideItem: PaintingGuides) => {
        dispatch(
          setPaintingGuides({
            ...paintingGuides,
            [guideItem]:
              paintingGuides[guideItem] !== PaintingGuideStatus.SHOW
                ? PaintingGuideStatus.SHOW
                : PaintingGuideStatus.HIDE,
          })
        );
      },
      [dispatch, paintingGuides]
    );

    const handleCloneLayer = useCallback(
      (
        layer: BuilderLayerJSON<MovableObjLayerData>,
        options?: CloneLayerOptions
      ) => {
        const centerPosition = getZoomedCenterPosition(
          stageRef,
          frameSize,
          zoom,
          boardRotate
        );

        dispatch(
          cloneLayer(layer, {
            ...options,
            centerPosition,
          })
        );
        focusBoard();
      },
      [dispatch, stageRef, frameSize, zoom, boardRotate]
    );
    const handleCloneLayerList = useCallback(
      (
        layers: BuilderLayerJSON<MovableObjLayerData>[],
        options?: CloneLayerListOptions
      ) => {
        dispatch(
          cloneLayers(layers, {
            ...options,
            centerPosition: getZoomedCenterPosition(
              stageRef,
              frameSize,
              zoom,
              boardRotate
            ),
          })
        );
        focusBoard();
      },
      [dispatch, stageRef, frameSize, zoom, boardRotate]
    );

    const handleGroupLayers = useCallback(
      (layers: BuilderLayerJSON[]) => {
        if (layers.length <= 1 || !currentScheme || !stageRef.current) return;

        // Getting Group Name here:
        let number = 1;

        for (const layerItem of layerList) {
          if (layerItem.layer_data.name.indexOf("Group") === 0) {
            const extraSpace = layerItem.layer_data.name.slice("Group".length);
            if (extraSpace.length) {
              number = extraSpace === "" ? 1 : parseInt(extraSpace) + 1;
            }
          }
        }

        const name = `Group ${number}`;

        const historyUuid = uuidv4();
        const nodeEdges = selectedLayerIds
          .map((id) => {
            const node = stageRef.current?.find(`.${id}`)[0];
            if (!node || !stageRef.current) return null;

            const rect = node.getClientRect({
              relativeTo: stageRef.current,
              skipShadow: true,
            });

            return {
              left: rect.x,
              top: rect.y,
              right: rect.x + rect.width,
              bottom: rect.y + rect.height,
            };
          })
          .filter((edge) => edge);
        const groupEdges = nodeEdges.reduce(
          (prev, current) => ({
            left: Math.min(prev?.left ?? 0, current?.left ?? 0),
            top: Math.min(prev?.top ?? 0, current?.top ?? 0),
            right: Math.max(prev?.right ?? 0, current?.right ?? 0),
            bottom: Math.max(prev?.bottom ?? 0, current?.bottom ?? 0),
          }),
          nodeEdges[0]
        );

        if (!groupEdges) return;

        const childNodeLayers = selectedLayers.flatMap((layer) =>
          layer.layer_type === LayerTypes.GROUP
            ? layerList.filter((l) =>
                (layer.layer_data as GroupObjLayerData).layers?.includes(l.id)
              )
            : [layer]
        ) as BuilderLayerJSON<MovableObjLayerData>[];

        const updatedChildLayers = childNodeLayers.map((layer) => {
          const node = stageRef.current?.find(`.${layer.id}`)[0];
          if (!node || !stageRef.current) return layer;

          const absoluteTransform = node
            .getAbsoluteTransform(stageRef.current)
            .decompose();

          return {
            ...layer,
            layer_data: {
              ...layer.layer_data,
              left: absoluteTransform.x - groupEdges.left,
              top: absoluteTransform.y - groupEdges.top,
              opacity: node.getAbsoluteOpacity(),
              rotation: node.getAbsoluteRotation() - boardRotate,
              width: layer.layer_data.width
                ? layer.layer_data.width * absoluteTransform.scaleX
                : undefined,
              height: layer.layer_data.height
                ? layer.layer_data.height * absoluteTransform.scaleY
                : undefined,
              scaleX: (layer.layer_data as TextObjLayerData).scaleX
                ? absoluteTransform.scaleX
                : undefined,
              scaleY: (layer.layer_data as TextObjLayerData).scaleY
                ? absoluteTransform.scaleY
                : undefined,
              skewX: absoluteTransform.skewX,
              skewY: absoluteTransform.skewY,
            },
          };
        });

        const selectedGroupLayers = selectedLayers.filter(
          (layer) => layer.layer_type === LayerTypes.GROUP
        );

        dispatch(setSelectedLayerIds([]));

        if (selectedGroupLayers.length) {
          dispatch(
            deleteLayerList(selectedGroupLayers, {
              hideSuccessMessage: true,
              historyUuid,
            })
          );
        }

        // Should Hide Child Layers for now to avoid flash, and revert it back after group.
        for (const layer of childNodeLayers) {
          const node = stageRef.current?.find(`.${layer.id}`)[0];
          node.visible(false);
        }

        const lowestOrder = childNodeLayers.reduce(
          (prev, current) => Math.min(prev, current.layer_order),
          Infinity
        );

        dispatch(
          groupLayers({
            schemeID: currentScheme.id,
            layers: childNodeLayers.map((layer) => layer.id),
            name,
            position: { x: groupEdges.left, y: groupEdges.top },
            size: {
              width: groupEdges.right - groupEdges.left,
              height: groupEdges.bottom - groupEdges.top,
            },
            historyUuid,
            layerOrder: lowestOrder,
            callback: () => {
              dispatch(bulkUpdateLayer(updatedChildLayers, { historyUuid }));
            },
          })
        );
      },
      [
        boardRotate,
        currentScheme,
        dispatch,
        layerList,
        selectedLayerIds,
        selectedLayers,
        stageRef,
      ]
    );

    const handleUnGroupLayer = useCallback(
      (groupLayer: BuilderLayerJSON<GroupObjLayerData>) => {
        if (
          groupLayer.layer_type !== LayerTypes.GROUP ||
          !groupLayer.layer_data.layers?.length ||
          !currentScheme ||
          !selectedLayerIds.includes(groupLayer.id)
        )
          return;

        const historyUuid = uuidv4();
        const layerIDs = groupLayer.layer_data.layers;

        const layers = layerList.filter((layerID) =>
          layerIDs?.includes(layerID.id)
        ) as BuilderLayerJSON<MovableObjLayerData>[];

        const updatedLayers = layers.map((layer) => {
          const node = stageRef.current?.find(`.${layer.id}`)[0];
          if (!node || !stageRef.current) return layer;

          const absoluteTransform = node
            .getAbsoluteTransform(stageRef.current)
            .decompose();

          return {
            ...layer,
            layer_data: {
              ...layer.layer_data,
              left: absoluteTransform.x,
              top: absoluteTransform.y,
              opacity: node.getAbsoluteOpacity(),
              rotation: node.getAbsoluteRotation() - boardRotate,
              width: layer.layer_data.width
                ? layer.layer_data.width * absoluteTransform.scaleX
                : undefined,
              height: layer.layer_data.height
                ? layer.layer_data.height * absoluteTransform.scaleY
                : undefined,
              scaleX: (layer.layer_data as TextObjLayerData).scaleX
                ? absoluteTransform.scaleX
                : undefined,
              scaleY: (layer.layer_data as TextObjLayerData).scaleY
                ? absoluteTransform.scaleY
                : undefined,
              skewX: absoluteTransform.skewX,
              skewY: absoluteTransform.skewY,
            },
          };
        });
        dispatch(bulkUpdateLayer(updatedLayers, { historyUuid }));
        dispatch(deleteLayer(groupLayer, { historyUuid }));
      },
      [
        dispatch,
        layerList,
        stageRef,
        boardRotate,
        currentScheme,
        selectedLayerIds,
      ]
    );

    const handleDeleteLayers = useCallback(
      async (layers: BuilderLayerJSON[]) => {
        const filteredLayersToDelete = layers.filter(
          (layer) =>
            layer.layer_type !== LayerTypes.CAR &&
            !(layer.layer_data as MovableObjLayerData)?.editLock
        );

        if (!filteredLayersToDelete.length) return;

        let deleteUpload = false;
        for (const layer of filteredLayersToDelete) {
          if (
            layer.layer_type === LayerTypes.UPLOAD &&
            uploadList.find(
              (item) => item.id === (layer.layer_data as UploadObjLayerData).id
            )
          ) {
            try {
              const schemes = await SchemeService.getSchemeListByUploadID(
                (layer.layer_data as UploadObjLayerData).id
              );
              if (schemes.length <= 1) {
                deleteUpload = true;
              }
            } catch (e) {
              console.error(e);
              deleteUpload = true;
            }
          }
        }

        dispatch(setPressedKey(null));
        dispatch(setPressedEventKey(null));

        const layerNames = filteredLayersToDelete.map(
          (layer) => `"${decodeHtml(layer.layer_data.name)}"`
        );
        setDeleteLayerState({
          show: true,
          deleteUpload,
          message: `Are you sure you want to delete ${layerNames.join(", ")}?`,
        });
      },
      [dispatch, uploadList]
    );

    const handleConfirmDelete = useCallback(
      (gonnaDeleteAll?: boolean) => {
        const filteredLayersToDelete = selectedLayers.filter(
          (layer) =>
            layer.layer_type !== LayerTypes.CAR &&
            !(layer.layer_data as MovableObjLayerData)?.editLock
        );
        const layersToDeleteIncludingGroup = filteredLayersToDelete.flatMap(
          (layer) =>
            layer.layer_type === LayerTypes.GROUP
              ? [
                  layer,
                  ...layerList.filter((l) =>
                    (layer.layer_data as GroupObjLayerData).layers?.includes(
                      l.id
                    )
                  ),
                ]
              : [layer]
        );

        if (layersToDeleteIncludingGroup.length) {
          dispatch(setPressedKey(null));
          dispatch(setPressedEventKey(null));
          dispatch(deleteLayerList(layersToDeleteIncludingGroup));
          if (gonnaDeleteAll) {
            // This is Uploads Layer, and gonna Delete it from uploads
            layersToDeleteIncludingGroup.forEach((layer) => {
              if (layer.layer_type === LayerTypes.UPLOAD) {
                dispatch(
                  deleteUpload(
                    { id: (layer.layer_data as UploadObjLayerData).id },
                    false
                  )
                );
              }
            });
          }
          setDeleteLayerState({});
        }
      },
      [dispatch, layerList, selectedLayers]
    );

    const handleChangeSelectedLayerOrder = useCallback(
      (isUpper = true) => {
        if (currentLayer && currentLayer.layer_type !== LayerTypes.CAR) {
          const exchangableLayers = _.orderBy(
            layerList.filter(
              (layer) =>
                (isUpper
                  ? layer.layer_order < currentLayer.layer_order
                  : layer.layer_order > currentLayer.layer_order) &&
                !(layer.layer_data as MovableObjLayerData)?.editLock &&
                isInSameSideBar(currentLayer.layer_type, layer.layer_type)
            ),
            ["layer_order"],
            isUpper ? ["desc"] : ["asc"]
          );
          if (exchangableLayers.length) {
            const layerToExchange = exchangableLayers[0];
            dispatch(
              updateLayer({
                id: layerToExchange.id,
                layer_order: +currentLayer.layer_order,
              })
            );
            dispatch(
              updateLayer({
                id: currentLayer.id,
                layer_order: +layerToExchange.layer_order,
              })
            );
          }
        }
      },
      [currentLayer, dispatch, layerList]
    );

    const handleChangeBoardRotation = useCallback(
      (isRight = true) => {
        let newBoardRotate;
        if (isRight) {
          newBoardRotate = boardRotate + 90;
          if (newBoardRotate >= 360) newBoardRotate = 0;
        } else {
          newBoardRotate = boardRotate - 90;
          if (newBoardRotate < 0) newBoardRotate = 270;
        }
        dispatch(setBoardRotate(newBoardRotate));
      },
      [boardRotate, dispatch]
    );

    // const handleDebouncedLayerDataUpdate = useDebouncedCallback(
    //   (layer_data) => {
    //     dispatch(
    //       updateLayer({
    //         id: currentLayer?.id,
    //         layer_data,
    //       })
    //     );
    //   },
    //   300
    // );

    const handleDebouncedBulkLayerDataUpdate = useDebouncedCallback(
      (layers: BuilderLayerJSON[]) => {
        dispatch(bulkUpdateLayer(layers));
      },
      300
    );

    const handleKeyEvent = useCallback(
      (key: string, event: KeyboardEvent) => {
        event.preventDefault();
        // Delete Selected Layer
        if ((event.target as HTMLElement)?.tagName === "INPUT") {
          return;
        }

        if (event.type === "keyup") {
          dispatch(setPressedKey(null));
          dispatch(setPressedEventKey(null));
        }

        if (event.type === "keydown") {
          if (
            pressedKey?.toString() === key?.toString() &&
            pressedEventKey?.toString() === event.key?.toString() &&
            !ArrowKeys.includes(event.key?.toString()) &&
            !BracketKeys.includes(event.key?.toString())
          ) {
            return;
          }
          if (pressedKey !== key) {
            dispatch(setPressedKey(key));
          }
          if (pressedEventKey !== event.key) {
            dispatch(setPressedEventKey(event.key));
          }
          if (key === "f5") {
            document.location.reload();
          } else if (
            (key === "del" || key === "backspace") &&
            selectedLayerIds.length &&
            editable
          ) {
            handleDeleteLayers(selectedLayers);
          } else if (key === "esc") {
            if (selectedLayerIds.length) {
              dispatch(setSelectedLayerIds([]));
            } else if (mouseMode !== MouseModes.DEFAULT) {
              dispatch(setMouseMode(MouseModes.DEFAULT));
              dispatch(setDrawingStatus(DrawingStatus.CLEAR_COMMAND));
            }
          } else if (event.key === "+" && event.shiftKey) {
            onZoomIn();
          } else if (event.key === "_" && event.shiftKey) {
            onZoomOut();
          } else if (event.key === ")" && event.shiftKey) {
            dispatch(setZoom(1));
          } else if (event.key === "(" && event.shiftKey) {
            onZoomFit();
          } else if (
            event.key === "[" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            handleChangeSelectedLayerOrder(false);
          } else if (
            event.key === "]" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            handleChangeSelectedLayerOrder(true);
          } else if (event.key === "D" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.DEFAULT));
          } else if (event.key === "B" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.PEN));
          } else if (event.key === "R" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.RECT));
          } else if (event.key === "C" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.CIRCLE));
          } else if (event.key === "E" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.ELLIPSE));
          } else if (event.key === "S" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.STAR));
          } else if (event.key === "G" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.RING));
          } else if (event.key === "O" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.REGULARPOLYGON));
          } else if (event.key === "W" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.WEDGE));
          } else if (event.key === "A" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.ARC));
          } else if (event.key === "P" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.POLYGON));
          } else if (event.key === "L" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.LINE));
          } else if (event.key === ">" && event.shiftKey && editable) {
            dispatch(setMouseMode(MouseModes.ARROW));
          } else if (key === "t" && editable) {
            setDialog(DialogTypes.TEXT);
          } else if (enableSimPreview && key === "p" && isWindows()) {
            dispatch(setAskingSimPreviewByLatest(true));
          } else if (key === "s" && editable) {
            setDialog(DialogTypes.SHAPE);
          } else if (key === "l" && editable) {
            setDialog(DialogTypes.LOGO);
          } else if (key === "b" && editable) {
            setDialog(DialogTypes.BASEPAINT);
          } else if (
            event.key === "c" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            const layersToClone = selectedLayers.filter(
              (layer) =>
                layer.layer_type !== LayerTypes.CAR &&
                !(layer.layer_data as MovableObjLayerData)?.editLock
            );
            dispatch(setClipboardLayers(layersToClone));
          } else if (
            event.key === "v" &&
            (event.ctrlKey || event.metaKey) &&
            clipboardLayers.length &&
            editable
          ) {
            handleCloneLayerList(
              clipboardLayers as BuilderLayerJSON<MovableObjLayerData>[],
              {
                selectAllAfterCreate: true,
              }
            );
          } else if (
            event.key === "z" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            dispatch(historyActionBack());
          } else if (
            event.key === "y" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            dispatch(historyActionUp());
          } else if (
            event.key === "g" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            if (event.shiftKey) {
              handleUnGroupLayer(
                selectedLayers[0] as BuilderLayerJSON<GroupObjLayerData>
              );
            } else {
              const layersToGroup = selectedLayers.filter(
                (layer) =>
                  layer.layer_type !== LayerTypes.CAR &&
                  !(layer.layer_data as MovableObjLayerData)?.editLock
              );
              handleGroupLayers(layersToGroup);
            }
          } else if (
            event.key === "j" &&
            (event.ctrlKey || event.metaKey) &&
            editable
          ) {
            const layersToClone = selectedLayers.filter(
              (layer) =>
                layer.layer_type !== LayerTypes.CAR &&
                !(layer.layer_data as MovableObjLayerData)?.editLock
            );
            if (layersToClone.length) {
              handleCloneLayerList(
                clipboardLayers as BuilderLayerJSON<MovableObjLayerData>[],
                {
                  selectAllAfterCreate: true,
                }
              );
            }
          } else if (event.key === "=" && (event.ctrlKey || event.metaKey)) {
            const newBrowserZoom = browserZoom * 1.25;
            document.body.style.transform = `scale(${newBrowserZoom})`;
            document.body.style.transformOrigin = "0 0";

            if (detectBrowser() === Browser.FIREFOX) {
              (document.body
                .style as CSSStyleDeclarationWithMozTransform).MozTransform = `scale(${newBrowserZoom})`;
            }
            setBrowserZoom(newBrowserZoom);
          } else if (event.key === "-" && (event.ctrlKey || event.metaKey)) {
            const newBrowserZoom = browserZoom / 1.25;
            document.body.style.transform = `scale(${newBrowserZoom})`;
            document.body.style.transformOrigin = "0 0";

            if (detectBrowser() === Browser.FIREFOX) {
              (document.body
                .style as CSSStyleDeclarationWithMozTransform).MozTransform = `scale(${newBrowserZoom})`;
            }
            setBrowserZoom(newBrowserZoom);
          } else if (event.key === "ArrowLeft" && event.altKey) {
            handleChangeBoardRotation(false);
          } else if (event.key === "ArrowRight" && event.altKey) {
            handleChangeBoardRotation(true);
          } else if (key === "1" && editable) {
            togglePaintingGuides(PaintingGuides.CARMASK);
          } else if (key === "2" && editable) {
            togglePaintingGuides(PaintingGuides.WIREFRAME);
          } else if (key === "3" && editable) {
            togglePaintingGuides(PaintingGuides.SPONSORBLOCKS);
          } else if (key === "4" && editable) {
            togglePaintingGuides(PaintingGuides.NUMBERBLOCKS);
          } else if (key === "5" && editable) {
            togglePaintingGuides(PaintingGuides.GRID);
          } else if (key === "enter" && editable) {
            if (
              [MouseModes.LINE, MouseModes.ARROW, MouseModes.POLYGON].includes(
                mouseMode
              )
            ) {
              dispatch(setDrawingStatus(DrawingStatus.ADD_TO_SHAPE));
            } else if (setSelectedLayerIds.length) {
              dispatch(setSelectedLayerIds([]));
            }
          }
        }

        // Arrow Keys
        if (editable && ArrowKeys.includes(event.key)) {
          const movableLayers = _.cloneDeep(
            selectedLayers.filter(
              (layer) =>
                ![LayerTypes.CAR, LayerTypes.BASE].includes(layer.layer_type)
            )
          );
          if (movableLayers.length) {
            const speed = event.shiftKey ? 10 : 1;
            const initialspeedX =
              event.key === "ArrowLeft"
                ? -speed
                : event.key === "ArrowRight"
                ? speed
                : 0;
            const initialspeedY =
              event.key === "ArrowUp"
                ? -speed
                : event.key === "ArrowDown"
                ? speed
                : 0;
            let speedX = initialspeedX;
            let speedY = initialspeedY;
            if (boardRotate === 90) {
              speedX = initialspeedY;
              speedY = -initialspeedX;
            } else if (boardRotate === 180) {
              speedX = -initialspeedX;
              speedY = -initialspeedY;
            } else if (boardRotate === 270) {
              speedX = -initialspeedY;
              speedY = initialspeedX;
            }
            if (prevTick.current != tick.current) {
              prevTick.current = +tick.current;

              for (const layer of movableLayers) {
                (layer.layer_data as MovableObjLayerData).left += speedX;
                (layer.layer_data as MovableObjLayerData).top += speedY;
              }

              dispatch(updateLayerListOnly(movableLayers));
              handleDebouncedBulkLayerDataUpdate(movableLayers);
            }
          }
        }
      },
      [
        editable,
        pressedKey,
        pressedEventKey,
        selectedLayerIds,
        enableSimPreview,
        clipboardLayers,
        selectedLayers,
        browserZoom,
        mouseMode,
        boardRotate,
        dispatch,
        onZoomIn,
        onZoomOut,
        onZoomFit,
        handleGroupLayers,
        handleUnGroupLayer,
        handleDeleteLayers,
        handleChangeSelectedLayerOrder,
        handleCloneLayerList,
        handleChangeBoardRotation,
        togglePaintingGuides,
        handleDebouncedBulkLayerDataUpdate,
      ]
    );

    useEffect(() => {
      if (editable && (pressedKey || pressedEventKey)) {
        const interval = setInterval(() => {
          tick.current += 1;
        }, 50);

        return () => {
          clearInterval(interval);
        };
      }
    }, [editable, pressedKey, pressedEventKey]);

    useEffect(() => {
      if (!isVisible) {
        dispatch(setPressedKey(null));
        dispatch(setPressedEventKey(null));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    useEffect(() => {
      window.addEventListener("blur", function () {
        dispatch(setPressedKey(null));
        dispatch(setPressedEventKey(null));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Component
          {...props}
          dialog={dialog}
          setDialog={setDialog}
          unsetDeleteLayerState={unsetDeleteLayerState}
          onKeyEvent={handleKeyEvent}
          onDeleteLayers={handleDeleteLayers}
          onCloneLayer={handleCloneLayer}
          onCloneLayerList={handleCloneLayerList}
          onGroupLayers={handleGroupLayers}
          onUngroupLayer={handleUnGroupLayer}
          onTogglePaintingGuides={togglePaintingGuides}
        />
        <LayerDeleteDialog
          text={deleteLayerState?.message}
          open={!!selectedLayerIds.length && deleteLayerState?.show}
          deleteUpload={deleteLayerState?.deleteUpload}
          onCancel={unsetDeleteLayerState}
          onConfirm={handleConfirmDelete}
        />
      </>
    );
  });
