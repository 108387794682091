import { Box, IconButton } from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  decodeHtml,
  focusBoardQuickly,
  getLayerName,
  stopPropagation,
} from "src/helper";
import { RootState } from "src/redux";
import { setContextMenu } from "src/redux/reducers/boardReducer";
import { MovableObjLayerData } from "src/types/common";
import { LayerTypes } from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";

import {
  CustomTypography,
  SmallIconButton,
  useStyles,
  Wrapper,
} from "./LayerItem.style";

export type LayerItemProps = {
  layerItem: BuilderLayerJSON;
  hoveredLayerJSON: Record<string | number, boolean>;
  disabled: boolean;
  disableLock: boolean;
  groupCompression: Record<string | number, boolean>;
  onToggleGroupCompression: (groupId: string | number, flag: boolean) => void;
  onSelect: (item: BuilderLayerJSON) => void;
  onDoubleClick?: () => void;
  onHover: (item: BuilderLayerJSON, hovered: boolean) => void;
  toggleField: (id: string | number, field: string) => void;
};

export const LayerItem = React.memo(
  ({
    layerItem,
    hoveredLayerJSON,
    disabled,
    disableLock,
    groupCompression,
    onToggleGroupCompression,
    onSelect,
    onDoubleClick,
    onHover,
    toggleField,
  }: LayerItemProps) => {
    const {
      layer_visible: layerVisible,
      layer_locked: layerLocked,
    } = layerItem;

    const wrapperRef = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.authReducer.user);
    const selectedLayerIds = useSelector(
      (state: RootState) => state.layerReducer.selectedLayerIds
    );

    const isGroupLayer = layerItem.layer_type === LayerTypes.GROUP;
    const isGroupExpanded = isGroupLayer && !groupCompression[layerItem.id];
    const hovered = hoveredLayerJSON[layerItem.id];
    const selected = selectedLayerIds.includes(layerItem.id);
    const showIcons = selected || hovered || layerLocked || !layerVisible;
    const itemDisabled =
      disabled || (layerItem.layer_data as MovableObjLayerData)?.editLock;
    const notAllowed = (layerItem.layer_data as MovableObjLayerData)?.editLock;
    const showLockIcon =
      !disableLock && !itemDisabled && (selected || hovered || layerLocked);
    const showInvisibleIcon =
      !itemDisabled && (selected || hovered || !layerVisible);

    const layerName = decodeHtml(
      getLayerName(layerItem.layer_data.name, layerItem.layer_type, user)
    );

    const handleToggleVisible = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        stopPropagation(e);
        toggleField(layerItem.id, "layer_visible");
        focusBoardQuickly();
      },
      [layerItem.id, toggleField]
    );

    const handleToggleLock = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        stopPropagation(e);
        toggleField(layerItem.id, "layer_locked");
        focusBoardQuickly();
      },
      [layerItem.id, toggleField]
    );

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        stopPropagation(e);
        onSelect(layerItem);
        focusBoardQuickly();
      },
      [layerItem, onSelect]
    );

    const handleExpandClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        stopPropagation(e);
        onToggleGroupCompression(layerItem.id, isGroupExpanded);
      },
      [isGroupExpanded, layerItem.id, onToggleGroupCompression]
    );

    const handleContextMenu = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        stopPropagation(e);

        if ((layerItem.layer_data as MovableObjLayerData).editLock) {
          return;
        }

        if (!selectedLayerIds.includes(layerItem.id)) {
          onSelect(layerItem);
        }

        dispatch(
          setContextMenu({
            x: e.pageX,
            y: e.pageY,
          })
        );
      },
      [dispatch, onSelect, selectedLayerIds, layerItem]
    );

    return (
      <Wrapper
        ref={wrapperRef}
        pr={2}
        pl={isGroupLayer ? 0 : 2}
        py={0.5}
        display="flex"
        alignItems="center"
        width="100%"
        borderBottom="1px solid gray"
        onClick={handleClick}
        onDoubleClick={onDoubleClick}
        onMouseEnter={() => onHover(layerItem, true)}
        onMouseLeave={() => onHover(layerItem, false)}
        onContextMenu={handleContextMenu}
        className={clsx(
          selected && "activeItem",
          hovered && "hoveredItem",
          notAllowed && "notAllowed"
        )}
      >
        {isGroupLayer ? (
          <IconButton
            size="small"
            onClick={handleExpandClick}
            className={clsx(classes.expand, {
              [classes.expandOpen]: isGroupExpanded,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        ) : (
          <></>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={isGroupLayer ? "calc(100% - 28px)" : "100%"}
          height="24px"
        >
          <CustomTypography
            variant="body2"
            active={layerVisible ? "true" : "false"}
            noWrap
          >
            {layerName}
          </CustomTypography>
          {showIcons ? (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {showLockIcon ? (
                <Box mr={1}>
                  <SmallIconButton onClick={handleToggleLock} size="small">
                    {layerLocked ? <LockIcon /> : <LockOpenIcon />}
                  </SmallIconButton>
                </Box>
              ) : (
                <></>
              )}
              {showInvisibleIcon ? (
                <SmallIconButton onClick={handleToggleVisible} size="small">
                  {layerVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </SmallIconButton>
              ) : (
                <Box width="24px" height="24px" />
              )}
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Wrapper>
    );
  }
);

export default LayerItem;
