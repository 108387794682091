import { KonvaEventObject } from "konva/lib/Node";
import { Stage } from "konva/lib/Stage";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultLayer } from "src/constant";
import {
  getCenterOfPoints,
  getCloningLayerID,
  getDistance,
  getRelativePointerPosition,
  removeDuplicatedPointFromEnd,
} from "src/helper";
import { RootState } from "src/redux";
import {
  setContextMenu,
  setIsDraggalbe,
  setMouseMode,
  setPaintingGuides,
  setZoom,
} from "src/redux/reducers/boardReducer";
import {
  createShape,
  setCloningLayers,
  setDrawingStatus,
  setSelectedLayerIds,
} from "src/redux/reducers/layerReducer";
import {
  DrawingLayerJSON,
  LineObjLayerData,
  MovableObjLayerData,
  Position,
  ShapeObjLayerData,
} from "src/types/common";
import {
  DrawingStatus,
  LayerTypes,
  MouseModes,
  PaintingGuides,
  PaintingGuideStatus,
} from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";
import { useDebouncedCallback } from "use-debounce";

import { useLayer } from "./useLayer";
import { useReducerRef } from "./useReducerRef";
import { ArrowKeys } from "./withKeyEvent";

export const useDrawHelper = (
  stageRef: RefObject<Stage | undefined>,
  boardWrapperRef: RefObject<HTMLDivElement | undefined>,
  editable: boolean
) => {
  const dispatch = useDispatch();

  const [prevPosition, setPrevPosition] = useState<Position>();
  const [previousPressedEventKey, setPreviousPressedEventKey] = useState<
    string | null
  >();
  const [showGuideForRepositioning, setShowGuideForRepositioning] = useState(
    false
  );
  const [drawingLayer, setDrawingLayer] = useState<
    BuilderLayerJSON<ShapeObjLayerData>
  >();

  const timerForGuideRepositioning = useRef<ReturnType<typeof setTimeout>>();

  const { selectedLayers } = useLayer();
  const mouseMode = useSelector(
    (state: RootState) => state.boardReducer.mouseMode
  );
  const pressedKey = useSelector(
    (state: RootState) => state.boardReducer.pressedKey
  );
  const pressedEventKey = useSelector(
    (state: RootState) => state.boardReducer.pressedEventKey
  );
  const currentScheme = useSelector(
    (state: RootState) => state.schemeReducer.current
  );
  const selectedLayerIds = useSelector(
    (state: RootState) => state.layerReducer.selectedLayerIds
  );
  const paintingGuides = useSelector(
    (state: RootState) => state.boardReducer.paintingGuides
  );
  const drawingStatus = useSelector(
    (state: RootState) => state.layerReducer.drawingStatus
  );
  const [, pressedKeyRef] = useReducerRef(pressedKey);

  let lastCenter: Position | null = null;
  let lastDist = 0;

  useEffect(() => {
    switch (drawingStatus) {
      case DrawingStatus.ADD_TO_SHAPE:
        if (drawingLayer) {
          const layer = {
            ...(drawingLayer ?? {}),
          };

          if (currentScheme) {
            dispatch(createShape(currentScheme.id, layer));
          }
          dispatch(setMouseMode(MouseModes.DEFAULT));
        }
        break;
      case DrawingStatus.CLEAR_COMMAND:
        setDrawingLayer(undefined);
        dispatch(setDrawingStatus(null));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingStatus]);

  const onContentMouseDown = useCallback(() => {
    if (mouseMode === MouseModes.DEFAULT || !stageRef.current || !currentScheme)
      return;

    const position = getRelativePointerPosition(stageRef.current);

    if (!position) return;

    if (!drawingLayer) {
      const newLayer: DrawingLayerJSON = {
        ...DefaultLayer,
        layer_type: LayerTypes.SHAPE,
        layer_data: {
          ...DefaultLayer.layer_data,
          type: mouseMode,
          name: mouseMode,
          left: position.x,
          top: position.y,
          color: currentScheme.guide_data.default_shape_color || "#000000",
          opacity: currentScheme.guide_data.default_shape_opacity || 1,
          scolor: currentScheme.guide_data.default_shape_scolor || "#000000",
          stroke: currentScheme.guide_data.default_shape_stroke || 0,
        },
      };

      if (
        [MouseModes.LINE, MouseModes.ARROW, MouseModes.POLYGON].includes(
          mouseMode
        )
      ) {
        newLayer.layer_data.stroke = 5;
        newLayer.layer_data.points = [0, 0, 0, 0];
      }
      if (mouseMode === MouseModes.PEN) {
        newLayer.layer_data.stroke = 5;
        newLayer.layer_data.points = [0, 0];
      }
      setDrawingLayer(newLayer as BuilderLayerJSON<ShapeObjLayerData>);
      dispatch(setDrawingStatus(DrawingStatus.DRAWING_SHAPE));
    } else {
      if (
        [MouseModes.LINE, MouseModes.ARROW, MouseModes.POLYGON].includes(
          mouseMode
        )
      ) {
        const layer = {
          ...drawingLayer,
          layer_data: {
            ...drawingLayer.layer_data,
            points: removeDuplicatedPointFromEnd(
              (drawingLayer.layer_data as LineObjLayerData).points
            ),
          },
        };
        layer.layer_data.points = layer.layer_data.points.concat([
          position.x - drawingLayer.layer_data.left,
          position.y - drawingLayer.layer_data.top,
        ]);

        setDrawingLayer(layer);
        dispatch(setDrawingStatus(DrawingStatus.DRAWING_SHAPE));
      }
    }
  }, [dispatch, mouseMode, currentScheme, drawingLayer, stageRef]);

  const contentMouseMoveCallback = useCallback(() => {
    if (mouseMode === MouseModes.DEFAULT || !drawingLayer || !stageRef.current)
      return;

    const position = getRelativePointerPosition(stageRef.current);
    if (!position) return;

    const drawingLayerData = drawingLayer.layer_data as LineObjLayerData;

    const width = position.x - drawingLayerData.left;
    const height = position.y - drawingLayerData.top;
    const positionX = position.x - drawingLayerData.left;
    const positionY = position.y - drawingLayerData.top;
    if (
      drawingLayerData.points.length < 2 ||
      positionX !==
        drawingLayerData.points[drawingLayerData.points.length - 2] ||
      positionY !== drawingLayerData.points[drawingLayerData.points.length - 1]
    ) {
      const layer = {
        ...drawingLayer,
        layer_data: {
          ...drawingLayerData,
          points: [...drawingLayerData.points],
          width: MouseModes.ELLIPSE === mouseMode ? Math.abs(width) : width,
          height: MouseModes.ELLIPSE === mouseMode ? Math.abs(height) : height,
          radius: Math.abs(width),
          innerRadius: Math.abs(width) / 2.5,
          outerRadius: Math.abs(width),
        },
      };
      if (
        [MouseModes.LINE, MouseModes.ARROW, MouseModes.POLYGON].includes(
          mouseMode
        )
      ) {
        layer.layer_data.points.splice(-2, 2, positionX, positionY);
      }
      if (mouseMode === MouseModes.PEN) {
        layer.layer_data.points.push(positionX);
        layer.layer_data.points.push(positionY);
      }
      setDrawingLayer(layer);
    }
  }, [drawingLayer, mouseMode, stageRef]);

  const onContentMouseMove = useDebouncedCallback(contentMouseMoveCallback, 5);

  const onContentMouseUp = useCallback(() => {
    if (
      ![
        MouseModes.DEFAULT,
        MouseModes.LINE,
        MouseModes.ARROW,
        MouseModes.POLYGON,
      ].includes(mouseMode)
    ) {
      dispatch(setDrawingStatus(DrawingStatus.ADD_TO_SHAPE));
    }
    if (!stageRef.current) return;

    const position = getRelativePointerPosition(stageRef.current);

    if (position) setPrevPosition(position);
  }, [mouseMode, stageRef, dispatch]);

  const onMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (mouseMode === MouseModes.DEFAULT) {
        const clickedOnEmpty = e.target === e.target.getStage();

        if (clickedOnEmpty) {
          if (selectedLayerIds.length) {
            dispatch(setSelectedLayerIds([]));
          }
        }
      } else {
        onContentMouseDown();
      }
    },
    [mouseMode, selectedLayerIds.length, dispatch, onContentMouseDown]
  );

  const onMouseMove = useCallback(() => {
    if (mouseMode !== MouseModes.DEFAULT) {
      onContentMouseMove();
    }
  }, [mouseMode, onContentMouseMove]);

  const onMouseUp = useCallback(() => {
    if (mouseMode !== MouseModes.DEFAULT) {
      onContentMouseUp();
    }
  }, [mouseMode, onContentMouseUp]);

  const onDoubleClick = useCallback(() => {
    if (!stageRef.current) return;

    const position = getRelativePointerPosition(stageRef.current);

    if (
      [MouseModes.LINE, MouseModes.ARROW, MouseModes.POLYGON].includes(
        mouseMode
      ) &&
      drawingLayer &&
      position &&
      prevPosition?.x === position.x &&
      prevPosition?.y === position.y
    ) {
      dispatch(setDrawingStatus(DrawingStatus.ADD_TO_SHAPE));
    }
  }, [stageRef, mouseMode, prevPosition, drawingLayer, dispatch]);

  const handleShowGuideForRepositioning = useCallback(
    (show = true) => {
      setShowGuideForRepositioning(show);
      if (!show) {
        const newPaintingGuides = { ...paintingGuides };
        Object.keys(paintingGuides).forEach((key) => {
          if (
            newPaintingGuides[key as PaintingGuides] ===
            PaintingGuideStatus.TEMP_SHOW
          ) {
            newPaintingGuides[key as PaintingGuides] = PaintingGuideStatus.HIDE;
          }
        });
        dispatch(setPaintingGuides(newPaintingGuides));
      } else {
        const newPaintingGuides = { ...paintingGuides };
        if (
          currentScheme?.guide_data.show_wireframe &&
          paintingGuides[PaintingGuides.WIREFRAME] !== PaintingGuideStatus.SHOW
        ) {
          newPaintingGuides[PaintingGuides.WIREFRAME] =
            PaintingGuideStatus.TEMP_SHOW;
        }
        if (
          currentScheme?.guide_data.show_numberBlocks &&
          paintingGuides[PaintingGuides.NUMBERBLOCKS] !==
            PaintingGuideStatus.SHOW
        ) {
          newPaintingGuides[PaintingGuides.NUMBERBLOCKS] =
            PaintingGuideStatus.TEMP_SHOW;
        }
        if (
          currentScheme?.guide_data.show_sponsor &&
          paintingGuides[PaintingGuides.SPONSORBLOCKS] !==
            PaintingGuideStatus.SHOW
        ) {
          newPaintingGuides[PaintingGuides.SPONSORBLOCKS] =
            PaintingGuideStatus.TEMP_SHOW;
        }
        if (
          currentScheme?.guide_data.show_grid &&
          paintingGuides[PaintingGuides.GRID] !== PaintingGuideStatus.SHOW
        ) {
          newPaintingGuides[PaintingGuides.GRID] =
            PaintingGuideStatus.TEMP_SHOW;
        }
        dispatch(setPaintingGuides(newPaintingGuides));
      }
    },
    [dispatch, paintingGuides, currentScheme]
  );
  const onLayerDragStart = (layer?: BuilderLayerJSON<MovableObjLayerData>) => {
    if (
      currentScheme?.guide_data.show_wireframe ||
      currentScheme?.guide_data.show_numberBlocks ||
      currentScheme?.guide_data.show_sponsor ||
      currentScheme?.guide_data.show_grid
    ) {
      handleShowGuideForRepositioning(true);
    }
    dispatch(setDrawingStatus(DrawingStatus.TRANSFORMING_SHAPE));

    const allowCloning = layer && pressedKeyRef.current === "alt";
    if (allowCloning) {
      // layer is in selectedLayerIds
      if (selectedLayerIds.includes(layer?.id ?? "")) {
        dispatch(
          setCloningLayers(
            selectedLayers.map((layerItem) => ({
              ...layerItem,
              id: getCloningLayerID(layerItem),
            }))
          )
        );
      } else {
        dispatch(
          setCloningLayers(
            selectedLayers.map((layer) => ({
              ...layer,
              id: getCloningLayerID(layer),
            }))
          )
        );
      }
    }
  };

  const onLayerDragEnd = useCallback(() => {
    if (
      currentScheme?.guide_data.show_wireframe ||
      currentScheme?.guide_data.show_numberBlocks ||
      currentScheme?.guide_data.show_sponsor ||
      currentScheme?.guide_data.show_grid
    )
      handleShowGuideForRepositioning(false);
    dispatch(setDrawingStatus(null));
  }, [dispatch, handleShowGuideForRepositioning, currentScheme]);

  useEffect(() => {
    // Show/hide Guide on pressing arrow keys
    if (
      !editable ||
      !selectedLayers.length ||
      selectedLayers.some((layer) =>
        [LayerTypes.CAR, LayerTypes.BASE].includes(layer.layer_type)
      )
    ) {
      return;
    }

    if (
      pressedEventKey &&
      ArrowKeys.includes(pressedEventKey) &&
      !showGuideForRepositioning
    ) {
      if (timerForGuideRepositioning.current) {
        clearTimeout(timerForGuideRepositioning.current);
      }

      handleShowGuideForRepositioning(true);
    }

    if (
      !pressedEventKey &&
      previousPressedEventKey &&
      ArrowKeys.includes(previousPressedEventKey) &&
      showGuideForRepositioning
    ) {
      if (timerForGuideRepositioning.current) {
        clearTimeout(timerForGuideRepositioning.current);
      }

      timerForGuideRepositioning.current = setTimeout(() => {
        if (showGuideForRepositioning) {
          handleShowGuideForRepositioning(false);
        }
      }, 500);
    }

    setPreviousPressedEventKey(pressedEventKey);

    return () => {
      if (timerForGuideRepositioning.current) {
        clearTimeout(timerForGuideRepositioning.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedEventKey]);

  const onDragEnd = undefined;

  const onContextMenu = useCallback(
    (e: KonvaEventObject<Event>) => {
      e.evt.preventDefault();
      const stage = e.target.getStage();
      if (e.target !== stage && stage && editable) {
        const position = stage.getPointerPosition();

        const wrapperClientRect = boardWrapperRef?.current?.getBoundingClientRect();
        if (position) {
          dispatch(
            setContextMenu({
              x: position.x + (wrapperClientRect?.x ?? 0),
              y: position.y + (wrapperClientRect?.y ?? 0),
            })
          );
        }
      }
    },
    [dispatch, editable, boardWrapperRef]
  );

  const onTap = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      if (mouseMode === MouseModes.DEFAULT) {
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
          e.evt.preventDefault();
        } else {
          onMouseDown(e);
        }
      } else {
        onContentMouseDown();
      }
    },
    [mouseMode, onMouseDown, onContentMouseDown]
  );

  const onTouchStart = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      if (mouseMode === MouseModes.DEFAULT) {
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
          e.evt.preventDefault();
        } else {
          onMouseDown(e);
        }
      } else {
        onContentMouseDown();
      }
    },
    [mouseMode, onMouseDown, onContentMouseDown]
  );

  const onTouchMove = (e: KonvaEventObject<TouchEvent>) => {
    if (mouseMode === MouseModes.DEFAULT) {
      e.evt.preventDefault();
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];
      const stage = stageRef.current;

      if (stage && touch1 && touch2) {
        dispatch(setIsDraggalbe(false));

        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!lastCenter) {
          lastCenter = getCenterOfPoints(p1, p2);
          return;
        }
        const newCenter = getCenterOfPoints(p1, p2);

        const dist = getDistance(p1, p2);

        if (!lastDist) {
          lastDist = dist;
        }

        // local coordinates of center point
        const pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX(),
        };

        const scale = stage.scaleX() * (dist / lastDist) * (dist / lastDist);

        dispatch(setZoom(scale));

        // calculate new position of the stage
        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;

        const newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        stage.position(newPos);
        stage.batchDraw();

        lastDist = dist;
        lastCenter = newCenter;
      }
    } else {
      onContentMouseMove();
    }
  };

  const onTouchEnd = () => {
    if (mouseMode === MouseModes.DEFAULT) {
      lastCenter = null;
      lastDist = 0;
      dispatch(setIsDraggalbe(true));
    } else {
      onContentMouseUp();
    }
  };

  const onDbltap = (e: KonvaEventObject<TouchEvent>) => {
    if (mouseMode === MouseModes.DEFAULT) {
      onContextMenu(e);
    } else {
      onDoubleClick();
    }
  };

  return {
    drawingLayer,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onContentMouseUp,
    onContentMouseMove,
    onContentMouseDown,
    onDoubleClick,
    onLayerDragStart,
    onLayerDragEnd,
    onDragEnd,
    onContextMenu,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onTap,
    onDbltap,
  };
};
