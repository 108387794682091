import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { URLImage } from "src/components/konva";
import { FinishOptions } from "src/constant";
import { generateCarMakeImageURL } from "src/helper";
import { useLayer, useScheme } from "src/hooks";
import { RootState } from "src/redux";
import { CarObjLayerData, MovableObjLayerData } from "src/types/common";
import { LayerTypes } from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";

type CarPartsProps = {
  virtual?: boolean;
  specMode?: boolean;
};

export const CarParts = React.memo(({ virtual, specMode }: CarPartsProps) => {
  const { legacyMode } = useScheme();
  const {
    layerList,
    loadedStatuses,
    onLoadLayer,
    onExpandFrameFromImage,
  } = useLayer();

  const carMake = useSelector(
    (state: RootState) => state.carMakeReducer.current
  );
  const filteredLayers = useMemo(
    () =>
      _.orderBy(
        layerList
          .filter((item) => item.layer_type === LayerTypes.CAR)
          .map((layer) => ({
            ...layer,
            id: `${virtual ? "virtual-" : ""}${layer.id?.toString() ?? ""}`,
          })),
        ["layer_order"],
        ["desc"]
      ) as BuilderLayerJSON<CarObjLayerData>[],
    [layerList, virtual]
  );
  const getCarMakeImage = useCallback(
    (layer_data: CarObjLayerData) =>
      generateCarMakeImageURL(layer_data, carMake, legacyMode),
    [legacyMode, carMake]
  );

  return (
    <>
      {filteredLayers.map((layer) => (
        <URLImage
          key={layer.id}
          id={layer.id}
          name={layer.id}
          loadedStatus={loadedStatuses[layer.id]}
          layer={(layer as unknown) as BuilderLayerJSON<MovableObjLayerData>}
          x={layer.layer_data.left ?? 0}
          y={layer.layer_data.top ?? 0}
          width={layer.layer_data.width ?? (legacyMode ? 1024 : 2048)}
          height={layer.layer_data.height ?? (legacyMode ? 1024 : 2048)}
          src={getCarMakeImage(layer.layer_data)}
          filterColor={
            specMode
              ? layer.layer_data.finish || FinishOptions[0].value
              : layer.layer_data.color
          }
          listening={false}
          visible={layer.layer_visible ? true : false}
          onLoadLayer={!virtual ? onLoadLayer : undefined}
          tellSize={onExpandFrameFromImage}
        />
      ))}
    </>
  );
});

export default CarParts;
